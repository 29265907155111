<script setup lang="ts"></script>

<template>
  <svg
    width="124"
    height="32"
    viewBox="0 0 124 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Logo">
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9465 14.4534C13.4131 15.2001 12.2931 15.2001 11.7731 14.4534L7.78648 8.80007C6.75982 7.34674 7.79982 5.32007 9.58648 5.32007C10.2531 5.32007 10.8798 5.62674 11.3065 6.14674L13.8798 9.36007C14.9598 10.7067 17.0131 10.6801 18.0665 9.32007L20.4798 6.2134C20.9065 5.6534 21.5731 5.3334 22.2798 5.3334C24.1198 5.3334 25.1998 7.4134 24.1331 8.92007L20.2265 14.4534C19.6931 15.2001 18.5731 15.2001 18.0531 14.4534L17.0931 13.0934C16.5598 12.3467 15.4398 12.3467 14.9198 13.0934L13.9598 14.4534H13.9465Z"
        fill="url(#paint0_linear_1916_10655)"
      />
      <path
        id="Vector_2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.2268 17.5467C19.6935 16.8 18.5735 16.8 18.0535 17.5467L17.0935 18.9067C16.5602 19.6534 15.4402 19.6534 14.9202 18.9067L13.9602 17.5467C13.4268 16.8 12.3068 16.8 11.7868 17.5467L7.80015 23.2C6.77349 24.6534 7.81349 26.68 9.60015 26.68C10.2668 26.68 10.8935 26.3734 11.3202 25.8534L13.8935 22.64C14.9735 21.2934 17.0268 21.32 18.0802 22.68L20.4935 25.7867C20.9202 26.3467 21.5868 26.6667 22.2935 26.6667C24.1335 26.6667 25.2135 24.5867 24.1468 23.08L20.2402 17.5467H20.2268Z"
        fill="url(#paint1_linear_1916_10655)"
      />
      <path
        id="Vector_3"
        d="M27.6265 16.6667L26.4932 18.64C25.9865 19.5334 24.6932 19.5334 24.1732 18.64L23.0399 16.6667C22.7999 16.2534 22.7999 15.76 23.0399 15.3467L24.1732 13.3734C24.6799 12.48 25.9732 12.48 26.4932 13.3734L27.6265 15.3467C27.8665 15.76 27.8665 16.2534 27.6265 16.6667Z"
        fill="url(#paint2_linear_1916_10655)"
      />
      <path
        id="Vector_4"
        d="M8.96003 15.3333L7.82669 13.3599C7.32003 12.4666 6.02669 12.4666 5.50669 13.3599L4.37336 15.3333C4.13336 15.7466 4.13336 16.2399 4.37336 16.6533L5.50669 18.6266C6.01336 19.5199 7.30669 19.5199 7.82669 18.6266L8.96003 16.6533C9.20003 16.2399 9.20003 15.7466 8.96003 15.3333Z"
        fill="url(#paint3_linear_1916_10655)"
      />
      <g id="VaultX">
        <path
          d="M51.6295 6.60579L44.8363 26.396H41.4597L34.6665 6.60579H37.9368L42.0313 19.0051C42.1554 19.3661 42.2883 19.8038 42.4301 20.3181C42.5808 20.8325 42.7182 21.3514 42.8422 21.8748C42.9752 22.3982 43.0771 22.863 43.148 23.2691C43.2189 22.863 43.3164 22.3982 43.4405 21.8748C43.5734 21.3514 43.7108 20.8325 43.8526 20.3181C43.9944 19.8038 44.1273 19.3616 44.2514 18.9916L48.3592 6.60579H51.6295Z"
          fill="#F5F5F5"
        />
        <path
          d="M59.6324 11.1269C61.4935 11.1269 62.8982 11.5466 63.8465 12.3858C64.8037 13.2251 65.2823 14.5336 65.2823 16.3114V26.396H63.0622L62.464 24.2708H62.3576C61.9411 24.8122 61.5113 25.2589 61.0681 25.6109C60.625 25.9628 60.111 26.2245 59.526 26.396C58.95 26.5765 58.2454 26.6667 57.4123 26.6667C56.5349 26.6667 55.7506 26.5043 55.0593 26.1794C54.368 25.8455 53.823 25.3401 53.4241 24.6633C53.0253 23.9865 52.8259 23.1292 52.8259 22.0914C52.8259 20.5483 53.3887 19.3886 54.5142 18.6126C55.6487 17.8365 57.3591 17.4078 59.6457 17.3266L62.1981 17.2319V16.4467C62.1981 15.409 61.9588 14.669 61.4802 14.2268C61.0105 13.7846 60.3458 13.5635 59.4862 13.5635C58.7506 13.5635 58.0371 13.6718 57.3458 13.8884C56.6546 14.1049 55.981 14.3712 55.3252 14.687L54.3148 12.44C55.0327 12.0519 55.8481 11.7361 56.7609 11.4924C57.6826 11.2488 58.6398 11.1269 59.6324 11.1269ZM62.1848 19.2352L60.2838 19.3029C58.724 19.3571 57.6294 19.6278 57.0002 20.1151C56.371 20.6024 56.0563 21.2702 56.0563 22.1185C56.0563 22.8585 56.2735 23.3999 56.7077 23.7428C57.142 24.0767 57.7136 24.2437 58.4226 24.2437C59.5039 24.2437 60.399 23.9324 61.108 23.3097C61.8259 22.678 62.1848 21.753 62.1848 20.5347V19.2352Z"
          fill="#F5F5F5"
        />
        <path
          d="M82.4845 11.4112V26.396H80.0252L79.5998 24.3791H79.427C79.1168 24.8934 78.7224 25.3221 78.2438 25.665C77.7652 25.9989 77.2335 26.2471 76.6485 26.4095C76.0636 26.581 75.4477 26.6667 74.8007 26.6667C73.6929 26.6667 72.7446 26.4772 71.9558 26.0982C71.1759 25.7101 70.5777 25.1145 70.1611 24.3114C69.7446 23.5082 69.5363 22.4704 69.5363 21.198V11.4112H72.6737V20.6024C72.6737 21.7665 72.9041 22.6374 73.3649 23.2149C73.8347 23.7925 74.5614 24.0813 75.5451 24.0813C76.4934 24.0813 77.2468 23.8827 77.8051 23.4857C78.3634 23.0886 78.7578 22.502 78.9883 21.7259C79.2275 20.9498 79.3472 19.9978 79.3472 18.8698V11.4112H82.4845Z"
          fill="#F5F5F5"
        />
        <path
          d="M90.1019 26.396H86.9646V5.33337H90.1019V26.396Z"
          fill="#F5F5F5"
        />
        <path
          d="M100.046 24.0948C100.453 24.0948 100.857 24.0587 101.255 23.9865C101.654 23.9053 102.018 23.8105 102.346 23.7022V26.1117C102 26.2651 101.552 26.396 101.003 26.5043C100.453 26.6126 99.8818 26.6667 99.288 26.6667C98.4549 26.6667 97.706 26.5268 97.0413 26.2471C96.3766 25.9583 95.8493 25.4665 95.4594 24.7716C95.0694 24.0767 94.8744 23.1157 94.8744 21.8884V13.8342H92.8671V12.4129L95.0207 11.2894L96.0443 8.08126H98.0118V11.4112H102.226V13.8342H98.0118V21.8478C98.0118 22.6058 98.1979 23.1698 98.5701 23.5398C98.9423 23.9098 99.4342 24.0948 100.046 24.0948Z"
          fill="#F5F5F5"
        />
        <path
          d="M120 26.396H116.371L111.478 18.2877L106.56 26.396H103.143L109.604 16.1354L103.582 6.60579H107.105L111.625 14.1049L116.118 6.60579H119.535L113.486 16.2302L120 26.396Z"
          fill="#F5F5F5"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1916_10655"
        x1="15.9731"
        y1="5.3334"
        x2="15.9731"
        y2="15.0267"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#818181" />
        <stop offset="1" stop-color="#F5F5F5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1916_10655"
        x1="15.9735"
        y1="26.6667"
        x2="15.9735"
        y2="16.9734"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#818181" />
        <stop offset="1" stop-color="#F5F5F5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1916_10655"
        x1="25.3332"
        y1="20.6667"
        x2="25.3332"
        y2="11.3334"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#818181" />
        <stop offset="1" stop-color="#F5F5F5" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1916_10655"
        x1="6.66669"
        y1="11.3333"
        x2="6.66669"
        y2="20.6666"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#818181" />
        <stop offset="1" stop-color="#F5F5F5" />
      </linearGradient>
    </defs>
  </svg>
</template>

<style scoped lang="scss"></style>
